<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="'1500px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="qte22"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    :label="item.unit_name ? item.unit_name : 'Quantité'"
                    readonly
                    :rules="[(v) => v > 0 || 'Quantité obligatoire']"
                    hide-spin-buttons
                    autofocus
                    @keydown.enter="save"
                    :disabled="save_disable"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="dispo_headers"
                        :title="'Expedition Actuelle'"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showstd="true"
                        :Total="true"
                        :print="false"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="6">
                  <listitems
                    :list="form_list"
                    :headers="select_headers"
                    :title="'Nouveau Bon d\'expedition '"
                    :master="true"
                    :add="false"
                    :del="false"
                    :Update="false"
                    :showstd="true"
                    :Total="true"
                    :print="false"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    @add="add_item"
                    @delete="del_item"
                    :multiple="true"
                    @selected_rows="selected_right_change"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
const confirmdialog = () => import("./ConfirmDialog.vue");
const listitems = () => import("./ListItems.vue");
import { colisbl } from "print/data.js";
export default {
  components: { confirmdialog, listitems },
  name: "mvmscinderdetail",
  props: {
    item: Object,
    showForm: Boolean,
  },
  data: () => ({
    alert: false,
    save_disable: false,
    type_alert: "info",
    message: "",
    kflist: 100,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    newdescription: "",
    olddescription: "",
    dispo_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        dec: 0,
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
      },
    ],
    select_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        dec: 0,
        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
      },
    ],
    form_list: [],
    selected_left: [],
    selected_right: [],
    frml: 600,
  }),

  computed: {
    flist() {
      let l = [];
      if (this.item)
        if (this.item.colis_list)
          l = this.item.colis_list.filter(
            (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
          );
      return l;
    },

    showDetail() {
      return this.showForm;
    },
    formTitle() {
      return this.item.label;
    },
    linked() {
      let ok = false;
      if (this.item.links) ok = this.item.links.length > 0;
      return ok;
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      let v_min = 0;

      if (this.linked) {
        let l = [];
        this.item.links.forEach((element) => {
          let v =
            list
              .filter((elm) => elm.article_id == element.link_id)
              .reduce((a, b) => a + (b["qte"] || 0), 0) / element.ratio;
          l.push(v / element.ratio);
        });
        v_min = Math.min(...l);
      } else v_min = list.reduce((a, b) => a + (b["qte"] || 0), 0);
      return parseFloat(v_min.toFixed(this.item.decimal));
    },
  },
  watch: {
    form_list: {
      handler() {
        this.editedItem.qte = this.sumlist_selected.toFixed(this.item.decimal);
        if (colisbl) {
          this.newdescription = this.get_description(this.form_list);
          this.olddescription = this.get_description(this.flist);
        }
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    this.dispo_headers[1].hiden =
      this.$store.state.options[0].code_produit == "1";
    this.dispo_headers[2].hiden =
      this.$store.state.options[0].code_produit == "0";
    this.select_headers[1].hiden =
      this.$store.state.options[0].code_produit == "1";
    this.select_headers[2].hiden =
      this.$store.state.options[0].code_produit == "0";
    this.dispo_headers[4].text = this.item.unit
      ? "Quantité (" + this.item.unit + ")"
      : "Quantité";
    this.select_headers[4].text = this.item.unit
      ? "Quantité (" + this.item.unit + ")"
      : "Quantité";
    this.dispo_headers[4].dec = this.item.decimal;
    this.select_headers[4].dec = this.item.decimal;
    this.dispo_headers[3].hiden = !this.linked;
    this.select_headers[3].hiden = !this.linked;
    this.kflist++;
  },

  methods: {
    async verif_amalgame() {
      let ok = true;
      let a = "";

      if (this.flist.length > 0) {
        this.item.links.forEach((element) => {
          let v = this.form_list
            .filter((elm) => elm.article_id == element.link_id)
            .reduce((a, b) => a + (b["qte"] || 0), 0);
          let d = v / element.ratio - this.editedItem.qte;
          if (d > 0) {
            ok = false;
            a = a + "Article : " + element.label + " Quantité : " + d + "\n";
          }
        });
        if (a.length > 0) {
          this.snackbar = true;
          this.snackbar_text = "Attention Excédent de Produits\n" + a;
          this.snackbar_color = "error";
        }
      }
      return ok;
    },
    get_description(form_list) {
      let list = [];
      let d = "";
      if (form_list)
        form_list.forEach((element) => {
          let i = list.findIndex(
            (elm) =>
              elm.article_id == element.article_id && elm.qte == element.qte
          );
          if (i >= 0) list[i].count = list[i].count + 1;
          else
            list.push({
              article_id: element.article_id,
              label: element.label,
              qte: element.qte,
              count: 1,
            });
        });
      if (list.length > 0) {
        list.forEach((element) => {
          d =
            d +
            element.count +
            " " +
            this.item.colis_unit +
            (element.count > 1 ? "s" : "") +
            (this.linked ? " " + element.label : "") +
            " de " +
            element.qte +
            " " +
            this.item.unit +
            " " +
            "\n";
        });
        if (list.length > 1 && !this.linked)
          d =
            d +
            "Soit " +
            this.form_list.length +
            " " +
            this.item.colis_unit +
            "s pour un total de " +
            this.editedItem.qte +
            " " +
            this.item.unit;
      }
      return d;
    },
    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected;
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected;
        this.frml++;
        this.kflist++;
      }
    },

    del_item() {
      this.editedItem.qte = this.sumlist_selected;
    },
    add_item(item) {
      item.cmd_id = this.cmd_id;
      item.cmd_name = this.cmd_name;
      this.form_list.splice(item.index, 1, item);
    },

    async close() {
      if (this.form_list.length > 0 && this.item.id < 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir annuler"
          )
        )
          this.$emit("close", this.form_list);
      } else this.$emit("close", this.form_list);
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.save_disable = true;
        let ok = true;
        if (this.linked) ok = await this.verif_amalgame();
        if (ok) {
          this.$emit(
            "save",
            this.flist,
            this.form_list,
            this.newdescription,
            this.olddescription
          );
        }
        this.save_disable = false;
      }
    },
  },
};
</script>
